import Swiper from 'swiper';

new Swiper('.home__news-slider', {
  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 'auto',
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.home__news-slider__btn.swiper-button-next',
    prevEl: '.home__news-slider__btn.swiper-button-prev',
  },
  breakpoints: {
    640: {
      slidesPerGroup: 1,
    },
    768: {
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerGroup: 4,
    },
  },
});
